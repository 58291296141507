import { sortByStringLength } from "../../Utils/SortUtils";
import { PageHeader, SectionComponent, ServicesGrid, Subtitle } from "./Common";
import { SectionHeader } from "./Common/SectionHeader";

import HomeWash from "../../assets/HomeWash.jpg";
import BackyardWash from "../../assets/BackyardWash.jpg";
import SolarPanelClean from "../../assets/SolarPanelClean.jpg";

const services = [
  "Driveways & Garages",
  "Sidewalks & Walkways",
  "Solar Panels",
  "Decks, Patios, & Porches",
  "Exterior Walls & Fences",
  "Roofs & Gutters",
  "Decks & Wood Structures",
  "Concrete & Masonry",
].sort(sortByStringLength(false));

export const ResidentialServices = () => {
  return (
    <div className="h-full">
      <div className="bg-white">
        <div className="relative max-w-7xl flex flex-col gap-6 mx-auto py-12 px-4 sm:px-6 lg:px-16">
          <div className="mx-auto max-w-prose text-lg">
            <PageHeader
              title="Residential Services"
              subtitle="Exterior Home Pressure Washing"
            />
            <p className="mt-8 text-xl leading-8 text-gray-500">
              An exterior home pressure wash can enhance the curb appeal of a
              property, making it more attractive to potential buyers or
              visitors. It can also help to protect the home's exterior from
              damage caused by dirt, grime, and other environmental elements
              that can build up over time. Additionally, a pressure wash can
              help to maintain the integrity of a home's exterior surfaces,
              prolonging their lifespan and reducing the need for costly repairs
              down the road.
            </p>
          </div>
          <div className="prose prose-lg prose-bg-primary mx-auto text-gray-500">
            <p>
              Radical Pressure Washing provides a wide range of services to
              maintain the exterior of a home. Whether it's removing tough
              stains or restoring the original look of a home, Radical Pressure
              Washing has the expertise and equipment to get the job done right.
              Some of the services we offer include:
            </p>
            <ServicesGrid services={services} />
          </div>
          <hr />
          <SectionComponent
            imageAlt="Cleaning a muddy backyard with pressure washer"
            imagePosition="right"
            imageSrc={BackyardWash}
          >
            <Subtitle>Renovation Prep</Subtitle>
            <SectionHeader>
              Start your renovation project off right
            </SectionHeader>
            <p className="mt-8 text-lg text-gray-500">
              Radical Pressure Washing provides a number of services to prepare
              a home for renovation. We can remove paint, stains, and other
              debris from a home's exterior, making it easier to complete a
              renovation project.
            </p>
            <div className="prose prose-primary mt-5 text-gray-500">
              <p>
                Some of the common areas that need to be cleaned before a
                renovation project include:
              </p>
              <ul>
                <li>
                  Roofs and gutters (to remove debris and prevent injury to
                  workers)
                </li>
                <li>Driveways and sidewalks (to remove stains and debris)</li>
                <li>Exterior walls (to remove paint and stains)</li>
              </ul>
            </div>
          </SectionComponent>
          <hr />
          <SectionComponent
            imageAlt="Cleaning solar panels"
            imageSrc={SolarPanelClean}
          >
            <Subtitle>Solar Panels</Subtitle>
            <SectionHeader>Solar Panel Cleaning</SectionHeader>
            <p className="mt-8 text-lg text-gray-500">
              Solar panels are a great way to reduce a home's carbon footprint
              and save money on energy bills. However, they can become less
              efficient over time if they are not properly maintained. A solar
              panel cleaning can help to restore a solar panel's efficiency and
              extend its lifespan.
            </p>
            <div className="prose prose-primary mt-5 text-gray-500">
              <p>
                Radical Pressure Washing uses a soft wash technique to clean
                solar panels. This technique uses a low-pressure spray to remove
                dirt, grime, and other debris from solar panels without damaging
                them.
              </p>
            </div>
          </SectionComponent>
          <hr />
          <SectionComponent
            imageAlt="Washing walkway with pressure washer"
            imagePosition="right"
            imageSrc={HomeWash}
          >
            <Subtitle>Working with us</Subtitle>
            <SectionHeader>Satisfaction Guarantee</SectionHeader>
            <p className="mt-8 text-lg text-gray-500">
              Radical Pressure Washing is committed to providing the best
              possible service to our clients.
            </p>
            <div className="prose prose-primary mt-5 text-gray-500">
              <p>
                Radical Pressure Washing ensures the best results by using the
                latest and most advanced pressure washing techniques and
                equipment.
              </p>
              <p>
                We use eco-friendly cleaning solutions that are safe for the
                environment and the home's occupants.
              </p>
              <p>
                Additionally, Radical Pressure Washing provides a satisfaction
                guarantee, ensuring that our customers are completely satisfied
                with the results of our services.
              </p>
            </div>
          </SectionComponent>
        </div>
      </div>
    </div>
  );
};
