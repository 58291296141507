import { AppFooter } from "../AppFooter/AppFooter";
import { AppHero } from "./AppHero";
import { BusinessInfo } from "./BusinessInfo";
import { ServiceSection } from "./ServiceSection";

export const HomePage = () => {
  return (
    <>
      <AppHero />
      <ServiceSection />
      <BusinessInfo />
      <AppFooter />
    </>
  );
};
