import cx from "classnames";
import styles from "./SectionComponent.module.css";

export const SectionComponent = ({
  children,
  image,
  imageAlt,
  imageClass,
  imageSrc,
  imagePosition = "left",
}) => {
  return (
    <div className={styles.SectionComponent}>
      {(image !== undefined || imageSrc !== undefined) && (
        <div className={styles.imageContainer}>
          <div
            className={cx(imageClass, {
              [styles.leftImageContainer]: imagePosition === "left",
              [styles.rightImageContainer]: imagePosition === "right",
            })}
          >
            {image !== undefined ? (
              image
            ) : imageSrc !== undefined ? (
              <img
                className={styles.sectionImage}
                src={imageSrc}
                alt={imageAlt}
              />
            ) : undefined}
          </div>
        </div>
      )}
      <div className={styles.textContainer}>
        <div
          className={cx({
            [styles.rightTextContainer]: imagePosition === "left",
            [styles.leftTextContainer]: imagePosition === "right",
          })}
        >
          <div className={styles.sectionText}>{children}</div>
        </div>
      </div>
    </div>
  );
};
