import { useEffect, useState } from "react";
import { AppFooter } from "../AppFooter/AppFooter";
import { AppNav } from "../AppNav/AppNav";
import { AppPageHeader } from "../AppPageHeader/AppPageHeader";
import { ServicesNavigation } from "./ServicesNavigation";
import { ResidentialServices } from "./ResidentialServices";
import { CommercialServices } from "./CommercialServices";

const initialServiceGroups = [
  { name: "Residential", active: true },
  { name: "Commercial", active: false },
];

export const ServicesPage = () => {
  const [serviceGroups, setServiceGroups] = useState(initialServiceGroups);

  const handleServiceGroupClick = (index) => {
    setServiceGroups(
      serviceGroups.map((group, i) => ({
        ...group,
        active: i === +index,
      }))
    );
  };

  const activeGroup = serviceGroups.find((group) => group.active)?.name;

  useEffect(() => {
    return () => {
      setServiceGroups(initialServiceGroups);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <AppNav />
      <AppPageHeader
        title="Services"
        subtitle="We offer a wide range of services"
        content="We offer a wide range of services to help you clean and maintain your home or business. We are a veteran owned and operated business and we take pride in our work. We are fully insured and offer free estimates."
      />
      <div className="flex lg:justify-center items-center bg-gray-100 py-4 px-4 sm:px-6 lg:px-8">
        <ServicesNavigation
          serviceGroups={serviceGroups}
          setActiveGroup={handleServiceGroupClick}
        />
      </div>
      <div className="flex flex-col justify-center items-center gap-4 bg-primary-light py-8 px-4 sm:px-6 lg:px-8">
        {activeGroup !== "Commercial" && <ResidentialServices />}
        {activeGroup !== "Residential" && <CommercialServices />}
      </div>
      <AppFooter />
    </div>
  );
};
