import { sortByStringLength } from "../../Utils/SortUtils";
import clean from "../../assets/cleanTruck.jpeg";
import dirty from "../../assets/dirtyTruck.jpeg";
import dirtyBuilding from "../../assets/dirtyBuilding.jpeg";
import {
  PageHeader,
  SectionComponent,
  SectionHeader,
  ServicesGrid,
  Subtitle,
} from "./Common";

const services = [
  "Building Exteriors",
  "Parking Lots",
  "Driveways",
  "Storefronts",
  "Signs",
  "Graffiti Removal",
].sort(sortByStringLength(false));

export const CommercialServices = () => {
  return (
    <div className="h-full">
      <div className="bg-white">
        <div className="relative max-w-7xl flex flex-col gap-6 mx-auto py-12 px-4 sm:px-6 lg:px-16">
          <div className="mx-auto max-w-prose text-lg">
            <PageHeader
              title="Commercial Services"
              subtitle="Business Pressure Washing"
            />
            <p className="mt-8 text-xl leading-8 text-gray-500">
              In order to attract more customers, businesses need to maintain
              the appearance and cleanliness of their property, which can
              improve the customer experience and attract more visitors.
              Pressure washing can also help to prolong the lifespan of a
              building's exterior surfaces, reducing the need for costly repairs
              down the road. Additionally, regular pressure washing services can
              help to prevent safety hazards like slippery walkways and dirty or
              clogged gutters.
            </p>
          </div>
          <div className="prose prose-lg prose-bg-primary mx-auto text-gray-500">
            <p>
              Radical Pressure Washing provides a wide range of services to
              maintain the exterior of your business. Whether it's removing
              tough stains or restoring the original look of a building, Radical
              Pressure Washing has the expertise and equipment to get the job
              done right. Some of the services we offer include:
            </p>
            <ServicesGrid services={services} />
          </div>
          <hr />
          <SectionComponent
            imageAlt="A business front with a spray paint covering the face and sidewalk of the building"
            imageSrc={dirtyBuilding}
            imagePosition="right"
          >
            <Subtitle>Businesses & Commercial Properties</Subtitle>
            <SectionHeader className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Contractual Agreements
            </SectionHeader>
            <p className="mt-8 text-lg text-gray-500">
              Radical Pressure Washing offers a variety of services to maintain
              the exterior of your business. Whether it's removing tough stains
              or restoring the original look of a building, Radical Pressure
              Washing has the expertise and equipment to get the job done right.
            </p>
            <div className="prose prose-primary mt-5 text-gray-500">
              <p>
                We offer a variety of contract options to meet your business's
                needs. Our contracts are flexible and can be customized to fit
                your business's needs. We offer discounts for multiple locations
                and can provide additional services like window cleaning, gutter
                cleaning, and more.
              </p>
              <p>
                We also offer a variety of a-la-carte services to meet your
                needs. If you need a one-time service, we can provide that as
                well. We can also provide a free estimate for any contractual
                work.
              </p>
            </div>
          </SectionComponent>
          <hr />
          <SectionComponent
            imageAlt="Truck with spray paint on it"
            image={
              <>
                <img
                  className="h-56 w-[50%] object-cover lg:absolute lg:h-[50%] lg:w-full"
                  src={dirty}
                  alt="Truck with spray paint on it"
                />
                <img
                  className="h-56 w-[50%] object-cover lg:absolute lg:h-[50%] lg:w-full lg:bottom-0"
                  src={clean}
                  alt="Truck cleaned"
                />
              </>
            }
            imageClass="flex w-full"
          >
            <Subtitle>Flexibility & Convenience</Subtitle>
            <SectionHeader>Financial and Scheduling Flexibility</SectionHeader>
            <p className="mt-8 text-lg text-gray-500">
              Radical Pressure Washing is able to provide flexible scheduling
              and payment options to meet your business's needs.
            </p>
            <div className="prose prose-primary mt-5 text-gray-500">
              <p>
                With numerous payment options, we can work with your business to
                find a payment plan that works for you. We accept cash, check,
                and all major credit cards.
              </p>
              <p>
                We also offer flexible scheduling options to meet your
                business's needs. We can schedule services at a time that works
                for you, and we can also provide same-day services when needed.
              </p>
            </div>
          </SectionComponent>
        </div>
      </div>
    </div>
  );
};
