import { Routes, Route } from "react-router-dom";
import "./App.css";
import { HomePage } from "./components/HomePage/HomePage";
import { appNavigationMap } from "./models/constants";
import { ServicesPage } from "./components/ServicesPage/ServicesPage";
import { AboutPage } from "./components/AboutPage/AboutPage";
import { ContactPage } from "./components/Contact/Contact";

const App = () => {
  return (
    <Routes>
      <Route path={appNavigationMap.about.path} element={<AboutPage />} />
      <Route path={appNavigationMap.contact.path} element={<ContactPage />} />
      <Route path={appNavigationMap.services.path} element={<ServicesPage />} />
      <Route path={appNavigationMap.home.path} element={<HomePage />} />
      <Route path="*" element={<h1>404 Not Found</h1>} />
    </Routes>
  );
};

export default App;
