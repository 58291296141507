import AvailableMap from "../../assets/AvailableMap.png";
import { socialMediaMap } from "../../models/constants";

export const BusinessInfo = () => {
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            src={AvailableMap}
            alt="Map of Southern California, Arizona, and Nevada"
            className="w-full h-56 object-cover lg:absolute lg:h-full"
          />
        </div>
      </div>
      <div className="relative py-16 px-6 sm:py-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-32">
        <div className="lg:pr-8">
          <div className="mx-auto mx-w-md sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Location & Hours
            </h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              Radical Pressure Washing services location across Southern
              California, Arizona, and Nevada.
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              Normal business hours are Monday through Friday from 8:00 AM to
              5:00 PM.
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              Email Us:{" "}
              <span>
                <a
                  href={socialMediaMap.email.href}
                  className="text-primary-light hover:text-primary-dark underline"
                >
                  {socialMediaMap.email.text}
                </a>
              </span>
            </p>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              Call Us:{" "}
              <span>
                <a
                  href={socialMediaMap.phone.href}
                  className="text-primary-light hover:text-primary-dark underline"
                >
                  {socialMediaMap.phone.text}
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
