import { PropTypes } from "prop-types";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { appNavigationList } from "../../models/constants";
import { HomeLogoAnchor } from "./HomeLogoAnchor";

const AppMobileNav = (props) => {
  return (
    <Dialog
      as="div"
      open={props.mobileMenuOpen}
      onClose={props.setMobileMenuOpen}
    >
      <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
        <div className="flex flex-row-reverse items-center justify-between">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => props.setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <HomeLogoAnchor />
        </div>
        <div className="mt-6 space-y-2">
          {appNavigationList.map((item) => (
            <a
              key={item.name}
              href={item.path}
              className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
            >
              {item.name}
            </a>
          ))}
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

AppMobileNav.propTypes = {
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired,
};

export default AppMobileNav;
