import cx from "classnames";

export const ServicesNavigation = ({ serviceGroups, setActiveGroup }) => {
  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      <div className="sm:hidden flex justify-end w-full">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
          defaultValue={serviceGroups.find((tab) => tab.active)?.name}
          onChange={(e) => setActiveGroup(e.target.value)}
        >
          {serviceGroups.map((tab, index) => (
            <option key={tab.name} value={index}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block lg:mx-auto lg:flex lg:justify-center">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {serviceGroups.map((group, i) => (
              <button
                key={group.name}
                onClick={() => setActiveGroup(i)}
                className={cx(
                  group.active
                    ? "border-primary text-primary-dark"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {group.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
