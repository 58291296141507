// Components
import { AppNav } from "../AppNav/AppNav";

// Assets
import halfHalf from "../../assets/half_half_clean_roof.jpeg";

// Constants
import { appNavigationMap } from "../../models/constants";

export const AppHero = () => {
  return (
    <main className="relative bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 lg:w-full lg:max-w-2xl">
          <svg
            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>
          <AppNav />
          <div className="relative py-32 px-6 sm:py-40 lg:py-56 lg:px-8 lg:pr-0">
            <div className="text-center lg:text-left mx-auto max-w-2xl h-full flex flex-col justify-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Radical Pressure Washing
              </h1>
              <p className="mt-6 max-w-sm text-lg mx-auto lg:mx-0 lg:max-w-lg leading-8 text-gray-600">
                Veteran owned, operated, and capable of handling all your
                pressure washing needs
              </p>
              <div className="mt-10 flex items-center justify-center lg:justify-start space-x-4">
                <a
                  href={appNavigationMap.contact.path}
                  className="rounded-md bg-primary px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-dark"
                >
                  Book Now
                </a>
                <a
                  href={appNavigationMap.services.path}
                  className="rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-gray-600 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-dark"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <div
          className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full bg-overlay"
          style={{
            "--overlay-image": `url(${halfHalf})`,
            "--overlay-colors":
              "rgba(14, 47, 79, 0.25), rgba(32, 82, 148, 0.25)",
          }}
        />
      </div>
    </main>
  );
};
