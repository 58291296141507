import {
  BuildingOffice2Icon,
  HomeIcon,
  HomeModernIcon,
  MapIcon,
} from "@heroicons/react/24/outline";

const services = [
  {
    name: "Residential Cleaning",
    description:
      "A range of cleaning services for your home, including regular cleaning, move-in/move-out cleaning, and deep cleaning.",
    icon: HomeIcon,
  },
  {
    name: "Commercial Cleaning",
    description:
      "Services from a one-time cleaning to a regular cleaning schedule for your business. Business sizes range from small to large.",
    icon: BuildingOffice2Icon,
  },
  {
    name: "Roof Cleaning",
    description:
      "Roof cleaning services for residential and commercial properties. We use a soft wash method to clean your roof.",
    icon: HomeModernIcon,
  },
  {
    name: "Parking Lot Cleaning",
    description:
      "Parking lot cleaning services for residential and commercial properties. We use a soft wash method to clean your parking lot.",
    icon: MapIcon,
  },
];

export const ServiceSection = () => {
  return (
    <div className="bg-primary-dark py-16 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">
            All the services we provide to make your home or business clean
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Services include a free consultation and estimate to ensure you get
            the best service for your needs.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {services.map((service) => (
              <div key={service.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-50">
                  <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                    <service.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {service.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-300">
                  {service.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
