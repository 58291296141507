import { socialMediaList } from "../../models/constants";

export const AppFooter = () => {
  return (
    <footer className="bg-primary-dark">
      <div className="mx-auto max-w-7xl py-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {socialMediaList.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6 text-gray-400 hover:text-gray-500" />
            </a>
          ))}
        </div>
        <div>
          <p className="mt-8 text-center text-base text-gray-400 md:mt-0">
            &copy; 2022 Radical Pressure Washing LLC, All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
