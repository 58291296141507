export const PageHeader = ({ title, subtitle }) => (
  <h2>
    <span className="block text-center text-lg font-semibold text-primary-light">
      {title}
    </span>
    <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
      {subtitle}
    </span>
  </h2>
);
