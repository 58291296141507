import { appNavigationMap } from "../../models/constants";

import logo from "../../assets/transparent_bg_logo.png";

export const HomeLogoAnchor = () => {
  return (
    <a href={appNavigationMap.home.path} className="-m-1.5 p-1.5">
      <span className="sr-only">Radical Pressure Washing</span>
      <img alt="Radical Pressure Washing" className="h-8 w-auto" src={logo} />
    </a>
  );
};
