import { AppFooter } from "../AppFooter/AppFooter";
import { AppNav } from "../AppNav/AppNav";

export const AboutPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <AppNav />
      <div className="flex flex-col flex-grow justify-center items-center gap-4 bg-primary-light mt-8 py-8 px-4 sm:px-6 lg:px-8">
        <div className="h-full">
          <div className="bg-white">
            <div className="relative max-w-7xl flex flex-col gap-6 mx-auto py-12 px-4 sm:px-6 lg:px-16">
              <div className="mx-auto max-w-prose text-lg">
                <h2>
                  <span className="block text-center text-lg font-semibold text-primary-light">
                    About Us
                  </span>
                  <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    Veteran Owned and Operated
                  </span>
                </h2>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Radical Pressure Washing is more than just a pressure washing
                  company. It is a company founded on a deep sense of
                  patriotism, service, and a commitment to excellence. As a
                  veteran-owned and operated company, the founder, Eric, has
                  always been passionate about giving back to his community and
                  his country. After serving in the military, Eric decided to
                  start Radical Pressure Washing as a way to continue serving
                  his community while also providing for his family.
                </p>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Eric's passion for excellence is evident in everything he
                  does. He believes in providing the highest quality service to
                  his customers and will stop at nothing to ensure their
                  satisfaction. Eric's military background has instilled in him
                  a strong work ethic, attention to detail, and a commitment to
                  doing things the right way. As a result, Radical Pressure
                  Washing has become one of the most trusted and reliable
                  pressure washing companies in the area.
                </p>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Eric is deeply committed to his customers and his community.
                  He believes in giving back and supporting local businesses and
                  organizations. He is always looking for ways to improve his
                  company and his services, and is never satisfied with anything
                  less than excellence. Eric's passion for service, his
                  commitment to excellence, and his dedication to his community
                  make Radical Pressure Washing more than just a pressure
                  washing company - it is a company that truly cares about its
                  customers and its community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};
