import React from "react";
import { useState } from "react";

import { appNavigationList } from "../../models/constants";
import { Bars3Icon } from "@heroicons/react/24/outline";
import AppMobileNav from "./AppMobileNav";
import { HomeLogoAnchor } from "./HomeLogoAnchor";
import { NavLink } from "react-router-dom";

export const AppNav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="relative px-6 pt-6 lg:pl-8 lg:pr-0">
      <nav
        className="flex items-center justify-between sm:h-10 lg:justify-start"
        aria-label="Global"
      >
        <HomeLogoAnchor />
        <button
          type="button"
          className="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="hidden lg:ml-12 lg:block lg:space-x-14">
          {appNavigationList.map((item) => (
            <NavLink
              key={item.name}
              to={item.path}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </nav>
      <AppMobileNav
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
    </div>
  );
};
