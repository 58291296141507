export const ServicesGrid = ({ services }) => (
  <ul className="list-none grid grid-cols-1 md:grid-cols-2 text-center cursor-default pl-0">
    {services.map((item) => (
      <li
        key={item}
        className="text-primary-light font-semibold text-sm tracking-wide uppercase pl-0"
      >
        {item}
      </li>
    ))}
  </ul>
);
