import * as yup from "yup";
import { socialMediaMap } from "../../models/constants";
import cx from "classnames";

import { AppFooter } from "../AppFooter/AppFooter";
import { AppNav } from "../AppNav/AppNav";
import DarkBlue from "../../assets/blue_bg_logo_lg.jpg";
import { Formik, useField } from "formik";
import { generateMailToLink } from "../../Utils/GenUtils";
import { isEmptyString } from "../../Utils/CommonUtils";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "First name must be at least 2 characters")
    .required("First name is required")
    // is not an empty string
    .test("is-not-empty", "First name is required", (value) => {
      return !isEmptyString(value);
    }),
  lastName: yup
    .string()
    .min(2, "Last name must be at least 2 characters")
    .required("Last name is required")
    .test("is-not-empty", "Last name is required", (value) => {
      return !isEmptyString(value);
    }),
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Email is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .test("is-not-empty", "Phone number is required", (value) => {
      return !isEmptyString(value);
    }),
  company: yup.string().notRequired(),
  message: yup
    .string()
    .required("Message is required")
    .max(500, "Must be 500 characters or less"),
  referral: yup.string().notRequired(),
});

const initForm = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  company: "",
  message: "",
  referral: "",
};

export const ContactPage = () => {
  const handleSubmit = (values) => {
    // is valid
    if (
      !validationSchema.isValidSync(
        Object.entries(values).reduce((acc, [key, value]) => {
          return { ...acc, [key]: value?.trim() };
        }, {})
      )
    ) {
      return;
    }

    const name = `${values?.firstName} ${values?.lastName}`.trim();

    const subject = `Contact Form Submission${
      isEmptyString(name) ? "" : ` - ${name}`
    }`;

    const mailto = generateMailToLink({
      subject,
      body: Object.entries(values).reduce((acc, [key, value]) => {
        // bold the key
        return `${acc}${key}: ${
          isEmptyString(value) ? "N/A" : value.trim()
        } \r \r`;
      }, ""),
    });
    window.open(mailto);
  };

  return (
    <div className="bg-white">
      <AppNav />

      <main className="mt-8">
        {/* Header */}
        <div className="bg-primary py-24 sm:py-32">
          <div className="mx-auto max-w-md px-6 sm:max-w-lg lg:max-w-7xl lg:px-8">
            <h1 className="text-center text-4xl font-bold leading-10 tracking-tight text-white sm:text-5xl sm:leading-none lg:text-6xl">
              Get in touch
            </h1>
            <p className="mx-auto mt-6 max-w-3xl text-center text-xl leading-normal text-gray-50">
              We're here to help. Send us a message and we'll get back to you as
              soon as possible. We look forward to hearing from you!
            </p>
          </div>
        </div>

        {/* Contact Section */}
        <div className="relative bg-primary">
          <div className="lg:absolute lg:inset-0">
            <div className="z-10 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src={DarkBlue}
                alt="Dark Blue Logo Background"
              />
            </div>
          </div>
          <div className="relative bg-white py-16 px-6 sm:py-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-32">
            <div className="lg:pr-8 xl:pl-8">
              <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                  Let's work together
                </h2>
                <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                  We’d love to hear from you! Send us a message using the form
                  opposite, or email us. We’d love to hear from you! Send us a
                  message using the form opposite, or email us.
                </p>
                <Formik
                  initialValues={initForm}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <form
                      className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                      onSubmit={formik.handleSubmit}
                    >
                      <FormInput
                        label="First name"
                        name="firstName"
                        type="text"
                        required
                      />
                      <FormInput
                        label="Last name"
                        name="lastName"
                        type="text"
                        required
                      />
                      <FormInput
                        label="Email"
                        name="email"
                        type="email"
                        className="sm:col-span-2"
                        required
                      />
                      <FormInput
                        label="Phone"
                        name="phone"
                        type="text"
                        className="sm:col-span-2"
                        required
                      />
                      <FormInput
                        label="Company"
                        name="company"
                        type="text"
                        className="sm:col-span-2"
                      />
                      <FormTextArea
                        label={
                          <div className="flex justify-between">
                            <span>
                              Message<span className="text-red-500">*</span>
                            </span>
                            <span className="text-gray-500 text-sm">
                              Max. 500 characters
                            </span>
                          </div>
                        }
                        name="message"
                        type="text"
                        rows={4}
                        className="sm:col-span-2"
                      />
                      <FormInput
                        label="How did you hear about us?"
                        name="referral"
                        type="text"
                        className="sm:col-span-2"
                      />
                      <div className="text-right sm:col-span-2">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-grape-700 focus:outline-none focus:ring-2 focus:ring-grape-500 focus:ring-offset-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
                <div className="mt-6">
                  <hr />
                  <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                    Or call us at{" "}
                    <a
                      className="text-primary hover:text-primary-dark"
                      href={socialMediaMap.phone.href}
                    >
                      {socialMediaMap.phone.text}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AppFooter />
    </div>
  );
};

const errorClass =
  "border-red-500 bg-red-200 focus:border-red-500 focus:ring-red-500";

const FormInput = ({
  label,
  name,
  type,
  className,
  required = false,
  ...rest
}) => {
  const [field, meta] = useField(name);
  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name={name}
          id={name}
          className={cx(
            "block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm",
            {
              [errorClass]: meta.error !== undefined && meta.touched,
              "border-gray-300": !meta.error && meta.touched,
            }
          )}
          {...field}
          {...rest}
        />
        {meta.error !== undefined && meta.touched && (
          <div className="text-red-500 text-sm">{meta.error}</div>
        )}
      </div>
    </div>
  );
};

const FormTextArea = ({ label, name, type, className, ...rest }) => {
  const [field, meta] = useField(name);
  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <textarea
          type={type}
          name={name}
          id={name}
          className={cx(
            "block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm",
            {
              [errorClass]: meta.error !== undefined && meta.touched,
              "border-gray-300": !meta.error && meta.touched,
            }
          )}
          {...field}
          {...rest}
        />
        {meta.error !== undefined && meta.touched && (
          <div className="text-red-500 text-sm">{meta.error}</div>
        )}
      </div>
    </div>
  );
};
