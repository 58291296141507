import { socialMediaMap } from "../models/constants";
import { isEmptyString } from "./CommonUtils";

export const generateMailToLink = (params) => {
  const { subject, body } = params || {};
  const _params = [
    isEmptyString(subject) ? "" : `subject=${encodeURIComponent(subject)}`,
    isEmptyString(body) ? "" : `body=${encodeURIComponent(body)}`,
  ].filter((param) => !isEmptyString(param));
  const baseUri = `mailto:${socialMediaMap.email.text}`;

  if (_params.length === 0) {
    return baseUri;
  }

  return `${baseUri}?${_params.join("&")}`;
};
