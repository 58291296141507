import { FaFacebookF, FaInstagram, FaTiktok, FaYelp } from "react-icons/fa";
import { MdAlternateEmail, MdPhone } from "react-icons/md";

export const appNavigationMap = {
  home: {
    name: "Home",
    path: "/",
  },
  services: {
    name: "Services",
    path: "/services",
  },
  about: {
    name: "About",
    path: "/about",
  },
  contact: {
    name: "Contact",
    path: "/contact",
  },
};

export const appNavigationList = Object.values(appNavigationMap);

export const socialMediaMap = {
  facebook: {
    name: "Facebook",
    href: "https://www.facebook.com/radicalpressurewashig",
    icon: FaFacebookF,
  },
  instagram: {
    name: "Instagram",
    href: "https://www.instagram.com/radical_pressure_washing_llc/",
    icon: FaInstagram,
  },
  tiktok: {
    name: "TikTok",
    href: "https://www.tiktok.com/@radical_pressure_washing",
    icon: FaTiktok,
  },
  yelp: {
    name: "Yelp",
    href: "https://www.yelp.com/biz/radical-pressure-washing-riverside-3",
    icon: FaYelp,
  },
  email: {
    name: "Email",
    href: "mailto:radicalpressurewashing@gmail.com",
    text: "radicalpressurewashing@gmail.com",
    icon: MdAlternateEmail,
  },
  phone: {
    name: "Phone",
    href: "tel:707-507-9464",
    text: "707-507-9464",
    icon: MdPhone,
  },
};

export const socialMediaList = Object.values(socialMediaMap);
